body {
  font-family: Arial, Helvetica, sans-serif;
}

button{
  border: 1px solid black;
  background: transparent;
  border-radius: 4px;
  margin: 2px;
  font-size: 0.8em;
}