@tailwind base;
@tailwind components;
@tailwind utilities;

@layer component {
  /*generated with Input range slider CSS style generator (version 20211225)
  https://toughengineer.github.io/demo/slider-styler*/
  input[type=range].styled-slider {
    height: 1em;
    -webkit-appearance: none;
    background-color: transparent;
    --handleColor: #transparent;
    --handleHoverColor: #ff0000;
    --handleActiveColor: #ff0000;
    --fgColor: #000000;
  }

  /*progress support*/
  input[type=range].styled-slider.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(var(--ratio) * (100% - 1em)); /*Original:  --sx: calc(0.5 * 1em + var(--ratio) * (100% - 1em)); */
  }

  input[type=range].styled-slider:focus {
    outline: none;
  }

  /*webkit*/
  input[type=range].styled-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 0;
    background: var(--handleColor);
    border: 1px solid var(--fgColor);
    box-shadow: none;
    margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - max(1em * 0.5,1px));
  }

  input[type=range].styled-slider::-webkit-slider-runnable-track {
    height: 1em;
    border: 1px solid var(--fgColor);
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  input[type=range].styled-slider::-webkit-slider-thumb:hover {
    background: var(--handleHoverColor);
  }

  input[type=range].styled-slider::-webkit-slider-thumb:active {
    background: var(--handleActiveColor);
  }

  input[type=range].styled-slider.slider-progress::-webkit-slider-runnable-track {
    background: linear-gradient(var(--fgColor),var(--fgColor)) 0/var(--sx) 100% no-repeat, transparent;
  }

  /*mozilla*/
  input[type=range].styled-slider::-moz-range-thumb {
    width: max(calc(1em - 1px - 1px),0px);
    height: max(calc(1em - 1px - 1px),0px);
    border-radius: 0;
    background: var(--handleColor);
    border: 1px solid var(--fgColor);
    box-shadow: none;
  }

  input[type=range].styled-slider::-moz-range-track {
    height: max(calc(1em - 1px - 1px),0px);
    border: 1px solid var(--fgColor);
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }

  input[type=range].styled-slider::-moz-range-thumb:hover {
    background: var(--handleHoverColor);
  }

  input[type=range].styled-slider::-moz-range-thumb:active {
    background: var(--handleActiveColor);
  }

  input[type=range].styled-slider.slider-progress::-moz-range-track {
    background: linear-gradient(var(--fgColor),var(--fgColor)) 0/var(--sx) 100% no-repeat, transparent;
  }

  /*ms*/
  input[type=range].styled-slider::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  input[type=range].styled-slider::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }

  input[type=range].styled-slider::-ms-thumb {
    width: 1em;
    height: 1em;
    border-radius: 0;
    background: var(--handleColor);
    border: 1px solid var(--fgColor);
    box-shadow: none;
    margin-top: 0;
    box-sizing: border-box;
  }

  input[type=range].styled-slider::-ms-track {
    height: 1em;
    border-radius: 0;
    background: transparent;
    border: 1px solid var(--fgColor);
    box-shadow: none;
    box-sizing: border-box;
  }

  input[type=range].styled-slider::-ms-thumb:hover {
    background: var(--handleHoverColor);
  }

  input[type=range].styled-slider::-ms-thumb:active {
    background: var(--handleActiveColor);
  }

  input[type=range].styled-slider.slider-progress::-ms-fill-lower {
    height: max(calc(1em - 1px - 1px),0px);
    border-radius: 0px 0 0 0px;
    margin: -1px 0 -1px -1px;
    background: var(--fgColor);
    border: 1px solid var(--fgColor);
    border-right-width: 0;
  }

}